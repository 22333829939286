*,
:after,
:before {
  box-sizing: border-box;
}

.login-background {
  background-image: url('./assets/login_background.jpg');
  background-size: cover;
  display: flex;
  min-height: 100vh;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Login container */
.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 20px;
  padding-bottom: 30px;
  width: 350px;
  border-radius: 25px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
  background-color: #ebedef;
  opacity: 0;
  animation-delay: 0.5s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-name: fadeInUp;
}

@keyframes logo {
  from {
    transform: scale(1.5) translateY(50%);
  }

  to {
    transform: scale(1) translateY(0);
  }
}

/* Login form logo */
.login-form img {
  transform: scale(1.5) translateY(50%);
  animation: logo 0.75s ease 2s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Remainder of login elements to fade in */
.fade-in {
  opacity: 0;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-duration: 0.75s;
  animation-delay: 2.5s;
}

.sidebar-title {
  background-color: #323c55;
  text-align: center;
  color: white;
}

.sidebar-title>h3 {
  margin: 0;
  padding-left: 10px;
  margin-right: 20px;
}

.sidebar-item:hover {
  background-color: #586a97;
}