.day-card {
  position: relative;
  border: 1px solid #0000001a;
  border-radius: 0.8rem;
  flex-grow: 1;
  padding: 0.8rem;
  padding-left: 1rem;
  background-color: #fbfbfb;
  color: #343434;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.day-card:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px 0px #00000027;
}

.day-card-indicator {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0.6rem;
  background-color: #4aa36d;
}

.day-card-hover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgb(0 0 0 / 42%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.8rem;
}

/* Color legend */
.color-legend {
  opacity: 100%;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  transform: translateX(0px);
}

.color-legend.hidden {
  opacity: 0;
  transform: translateX(5px);
}